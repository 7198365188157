.overall-container{

}

.arrow-icon{
    font-size: 25px !important;
    color: white !important;
}
.header-title{
    font-size: 20px;
    font-weight: 700;
    color: white !important;
}
.loading{
    height: 80vh;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-content: center;
    flex-direction: column;
    background: rgba(0,0,0,0.07);
    position: absolute;
}

.image-container{
    margin: 10px 0px;
    height: 180px;
    background: #eee;
    width: 100%;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: relative;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border: 1px solid #eee;
}

.user-img-container{
    width: 100% !important;
    padding: 0px 20px !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    flex-direction: column !important;
}
.information-icon{
    font-size: 25px !important; 
    color: #777 !important; 
    margin-right: 10px !important;
}

.confirm-btn{
    width: 50% !important;
}
.personal-information-container{
    width: 100% !important;
    padding: 10px 20px !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    flex-direction: column !important;
}
.information-form{
 
}
.information-input-form{
    border-radius: 5px !important;
    padding: 5px !important;
    font-size: 18px !important;
    width: 100% !important;
}

.bank-form-container{
    width: 100%;
    padding: 10px 20px;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    flex-direction: column !important;
}
.bank-input{
    border-radius: 5px !important;
    font-size: 18px !important;
    margin: 10px 0px !important;
}
.mask-input-date{
    border-radius: 5px !important;
    padding: 7px !important;
    font-size: 16px !important;
    width: 100% !important;
    border: 1px solid #eaeaea !important;
}
.select-bank{ 
    width: 90vw !important;
    margin: 10px 0px !important;
    border-radius: 30px !important;
    font-size: 18px !important;   
}

.confirm-div{
    background: #006997 !important;
    align-items: center !important;
    padding: 10px 20px !important;
    display: flex !important;
    justify-content: center !important;
    border-radius: 30px !important;
}
.send-request-div{
    align-items: center !important;
    padding: 10px 0px !important;
    display: flex !important;
    justify-content: center !important;
    border-radius: 30px !important;
}
.confirm-btn{
    align-content: center !important;
    text-align: center !important;
    height: 58px !important;
    background: #008445 !important;
    border: 1px solid #C4C4C4 !important;
    border-radius: 30px !important;
}
.confirm-div-title{
    color: #fff !important;
    font-weight: 700 !important;
    font-size: 19px !important;
}