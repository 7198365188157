.head-text{
    font-size: 25px !important;
    font-weight: 500 !important ;
    align-self: center !important;
}
.head-text-container{
    text-align: center !important;
    padding: 25px 10px !important;
}
.loading{
    height: 80vh;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background: rgba(0,0,0,0.07);
    position: absolute;
}
.item-box{
    background: linear-gradient(180deg, rgba(0, 132, 69, 0.35) 0%, rgba(255, 255, 255, 0) 100%);
    border-radius: 10px;
    padding:20px 10px;
    border: 1px solid #c4c4c4;
    border-bottom-width: 1;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}
.warn-icon{
    font-size: 25px !important;
  font-weight: 900 !important ;
  color: red !important;
}
.exclamation-icon{
    font-size: 25px !important;
    font-weight: 900 !important ;
    color: #00A888 !important;
  }