.header-content{
    color: white !important;
    display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  background:  #006997;
}
.success-contract{
    width: 100%;
    padding: 10px 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.success-contract-title{
    font-size: 17px !important; 
    text-align: center !important;
}
.contact-container{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.contact{
    background: #006997;
    width: 90%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px;
    border-radius: 10px;
    padding: 5px;
}
.contact-text{
    font-size: 30px !important;
    color: red !important;
}
.contact-title{
    color: #fff !important;
    font-weight: 700 !important;
    font-size: 17px !important;
    text-align: center !important;
}
.loading{
    height: 80vh;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: absolute;
}
.create-contract-container{
    display: flex;
    justify-content: center;
    align-items: center;
   
}
.create-contract{
    background: #006997;
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 30px;
    padding: 5px;
}
.create-contract-title{
    color: #fff !important;
    font-weight: 700 !important;
    font-size: 17px !important;
}
.checking-container{
    padding: 40px;
    display: flex;
    flex-direction: column;
}
.refresh{
    display: flex;
    justify-content: flex-start;
    width: 100%;
}
.button-container{
    margin: 20px 10px !important;
}
.confirm-sign-btn{
    font-size: 16px !important;
    color: #fff !important;
    font-weight: bold !important;
}
.signing{
    border: 2px solid #999;
    border-radius: 5px;
    width: 100%;
}
.confirm-sign-div-container{
    display: flex;
    justify-content: center;
    margin-top: 20px;
}
.confirm-sign-div{
    width: 50%;
    background: #008445E5 ;
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: center;
    border-radius: 10px;
   height: 35px !important;
}
.resign{
    color: #fff !important;
    font-weight: 700 !important;
    font-size: 17px !important;
}